.position-relative {
  position: relative;
  
  >div:nth-child(2){
    display: flex;
    align-items: center;
    gap: 8px;

  }
}

.search-button {
  // position: absolute;
  // top: 50%;
  // left: 90%; /* Adjust the distance from the right as needed */
  // // transform: translateY(-50%);
  background: transparent; /* Remove default background */
  border: none; /* Remove default border */
  padding: 0; /* Remove default padding */
  cursor: pointer;
}

.search-icon {
  padding: 9px;
  border-radius: 8px;
  border: 1px solid rgb(255, 255, 255);
  color: #ffffff; /* Adjust the icon color as needed */
  font-size: 18px; /* Adjust the icon size as needed */
  background-color: #72C9D2;
}
