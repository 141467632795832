.detail-row {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.detail-col {
    padding-left: 20px;
}

.detail-group {
    margin: 15px 0px;
    /* overflow: hidden; */
    /* Add overflow handling */
}

.detail-group .detail-label {
    font-weight: bold;
    margin: 5px 0px;
}

.detail-group .detail-value {
    max-width: 100%;
    /* Adjust to your desired maximum width */
    overflow: hidden;
    text-overflow: ellipsis;
    /* Add ellipsis for overflow handling */
}


.detail-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.detail-link {
    margin-top: 10px;
    display: block;
    text-align: right;
    color: #007bff;
}

.detail-link:hover {
    text-decoration: none;
    color: #0056b3;
}